<template>
  <v-container fluid>
    <MDataTable
      title="Clients"
      :headers="[
        'Type',
        'Company',
        'Contact',
        'Plans',
      ]"
    ></MDataTable>
    <MNote
      :text="[
        //
      ]"
    ></MNote>
  </v-container>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
  </script>